import * as React from 'react';

import {Link} from '../../components/common';
import {appStoreLink, thinMargins, playStoreLink} from '../../constants';
import blueBackground from '../../images/blue-footer.png';
import playStore from '../../images/play-badge-1.png';
import appStore from '../../images/app-badge-1.png';

import {Socials} from './Socials';
import {ThemeColor} from '../../theme';

interface IFooter {
  bgColour?: ThemeColor;
}
export const Footer = ({bgColour}: IFooter) => {
  const background = bgColour ? `bg-${bgColour}` : 'bg-creole';
  return (
    <div
      style={{
        backgroundImage: `url(${blueBackground})`,
      }}
      className={` text-black w-100% h-fit relative -mt-20 z-30 bg-cover bg-top ${background} pb-16 md:pb-24 `}>
      <div className={`${thinMargins}`}>
        <div className="flex flex-col md:flex-row pt-20 justify-between gap-9">
          <div className="flex flex-col md:flex-row gap-9 md:gap-4">
            <div className="flex flex-col text-smallbody font-semibold text-black gap-2">
              <Link className="hover:text-hotcalypso" linkTo="home">
                home
              </Link>
              <Link className="hover:text-hotcalypso" linkTo="premium">
                luna premium
              </Link>
              <Link className="hover:text-hotcalypso" linkTo="about-us">
                about us
              </Link>
              <Link className="hover:text-hotcalypso" linkTo="teens">
                i&apos;m a teen
              </Link>
              <Link className="hover:text-hotcalypso" linkTo="parents">
                i&apos;m a parent/guardian
              </Link>
              <Link className="hover:text-hotcalypso" linkTo="work-with-us">
                work with us
              </Link>
              <Link className="hover:text-hotcalypso" linkTo="reports">
                insights reports
              </Link>
              <Link className="hover:text-hotcalypso" linkTo="articles">
                article library
              </Link>
              <Link className="hover:text-hotcalypso" linkTo="questions">
                question library
              </Link>
              <Link className="hover:text-hotcalypso" linkTo="shop">
                shop
              </Link>
              <Link
                className="hover:text-hotcalypso"
                linkTo="newsletter-sign-up">
                newsletter sign up
              </Link>
            </div>
            <div className="text-smallbody flex flex-col justify-between">
              <div>
                <p>contact us</p>
                <Link href="mailto:hello@weareluna.app">
                  <p className="font-title hover:text-hotcalypso">
                    hello@weareluna.app
                  </p>
                </Link>
              </div>
              <div className="flex flex-col gap-2 font-semibold">
                <Link
                  className="hover:text-hotcalypso "
                  linkTo="terms-conditions">
                  terms & conditions
                </Link>
                <Link className="hover:text-hotcalypso" linkTo="privacy-policy">
                  privacy policy
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <h4 className="text-subtitlesmall font-semibold md:text-subtitlelarge">
              join the luna community
            </h4>

            <div className="flex gap-4 pt-4 lg:pt-6  lg:justify-start h-[4rem] ">
              <Link href={appStoreLink} external>
                <img
                  src={appStore}
                  className="hover:opacity-90 max-h-[100%]"
                  alt="Apple app store button"
                />
              </Link>
              <Link href={playStoreLink} external>
                <img
                  src={playStore}
                  className="hover:opacity-90 max-h-[100%]"
                  alt="Google play store button"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="text-caption py-10 md:py-3">
          <Socials />
          <p>© 2023 by Luna Life Limited</p>
        </div>
      </div>
    </div>
  );
};
